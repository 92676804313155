.site-container{height: 100vh; width: 100vw; }
.site-logo{ 
    text-transform: uppercase;
    font-weight: 800;
    z-index: 1;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.2);
}
 .site-logo h1 {
    vertical-align: text-bottom;
    font-size: 16px;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 700;
    white-space: nowrap;
    margin-bottom: 0;
    color: white;
 }
  

.site-navbar{ display: flex; flex-direction: row; justify-content: space-between; align-items: center; padding: 0 14px;
     color: white;
     /*height: 32px; */}
/* .site-sidebar{height: calc(100vh - 32px) ; overflow: hidden;} */
/* .site-layout{height: calc(100vh - 32px);} */
.site-main{overflow-y: auto;} 

/* tinymce */
.modal-fullscreen,
.modal-fullscreen .ant-modal-content{ inset: 0 !important; height: 100vh !important; width: 100vw !important; margin: 0 !important; top: 0 !important; max-width: unset !important;}
.modal-fullscreen .ant-modal-header,
.modal-fullscreen .ant-modal-footer {border-radius: 0 !important;}
.modal-fullscreen .ant-modal-body { height: calc(100vh - 85px) !important; padding: 0 !important; overflow-y: auto !important; }
.modal-fullscreen .tox-tinymce {border: 0 !important; border-radius: 0 !important;}

.modal-form-logo,
.modal-form-cover{max-width: 100%; max-height: 100%;  object-fit: contain;}
.ant-upload-select{width: 100% !important;}
.ant-upload-list-item-image{ object-fit: cover !important;}
.device-simulator{ padding: 10px;}
.device-emulator section{ padding-bottom: 10px !important; margin: 0 !important; border: none !important;}


/* media size < 767 modal full screen */
@media (max-width: 767px) {
    .ant-modal,
    .ant-modal-content{ inset: 0 !important; height: 100vh !important; width: 100vw !important; margin: 0 !important; top: 0 !important; max-width: unset !important;border-radius: 0 !important;}
    .ant-modal-header,
    .ant-modal-footer {border-radius: 0 !important;}
    .ant-modal-body { height: calc(100vh - 110px) !important;  overflow-y: auto !important; }
}

/* quill编辑器 */
.quill-container__middle {
    .ql-container {
        min-height: 220px;
    }
}

.quill-container__small {
    .ql-container {
        min-height: 110px;
    }
}